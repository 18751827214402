import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchInitialData } from "../../redux/actions";

const Initialization = () => {
  const dispatch = useDispatch();
  const theApp = useSelector(({ sshepApp }) => sshepApp);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchInitialData());
    }
  }, []);

  return null;
};

export default Initialization;
