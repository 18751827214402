import axios from "axios";

const apiAxios = axios.create({
  baseURL: '',
  //baseURL: '',
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
// Use the one-shot token if it exists, else fall back to regular token
apiAxios.interceptors.request.use(function (config) {
  let ss = localStorage.getItem("sshepherdSecurity");
  let ssParsed = ss ? JSON.parse(ss) : null;
  let configMethod = config.method?.replace("'", "").toUpperCase();

  if (ssParsed?.oneshot_endpoints?.length > 0) {
    let found = ssParsed?.oneshot_endpoints.find((e) => {
      return (
        e.method?.toUpperCase().includes(configMethod) &&
        RegExp(e?.path?.replaceAll("(?P<", "(?<")).test(config.url)
      ); // convert from python regex to js regex
    });

    if (found) {
      // Use one-shot token
      let oneshotToken = localStorage.getItem("oneshotToken");
      if (oneshotToken?.length > 0) {
        config.headers["Authentication-Token"] = oneshotToken;
        console.log("Using One-Shot Token for API call", {
          oneshotToken,
          config,
        });
      }
    } else {
      // Use console login token
      let token = localStorage.getItem("token");
      config.headers["Authentication-Token"] = token;
      console.log("Using Console Login Token for API call", { token, config });
    }
  }

  return config;
});

/*
// After response, remove any one-shot token
apiAxios.interceptors.response.use(
  function (response) {
    let ss = localStorage.getItem("sshepherdSecurity");
    let ssParsed = ss ? JSON.parse(ss) : null;
    let responseMethod = response.config?.method?.replace("'", "").toUpperCase();

    if (ssParsed?.oneshot_endpoints?.length > 0) {
      let found = ssParsed?.oneshot_endpoints.find((e) => {
        return (
          e.method?.toUpperCase().includes(responseMethod) &&
          RegExp(e?.path?.replaceAll("(?P<", "(?<")).test(response.config?.url)
        ); // convert from python regex to js regex
      });

      if (found) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        localStorage.removeItem("oneshotToken"); // clear oneshot token after api call
        console.log("Removing One-Shot Token");
      }
      return response;
    }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    localStorage.removeItem("oneshotToken"); // clear oneshot token after api call
    console.log("Removing One-Shot Token");
    return Promise.reject(error);
  }
);
*/

export default apiAxios;
